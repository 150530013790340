import React from 'react'
import { Fade } from 'react-awesome-reveal'
import Section from 'gatsby-theme-mate/src/components/Section'
import { CardContainer } from 'gatsby-theme-mate/src/components/Card'
import Triangle from 'gatsby-theme-mate/src/components/Triangle'
import { useHashnodeQuery } from '../queries/useHashnodeQuery'
import { Post } from '../components/Post'
import { SECTION } from 'gatsby-theme-mate/src/utils/constants'

const Writing = () => {
  const {posts} = useHashnodeQuery()
  
  return (
    <Section.Container id={SECTION.writing} Background={Background}>
      <Section.Header name={SECTION.writing} icon="✍🏾" label="writing" />
      <CardContainer minWidth="300px">
        <Fade direction="down" triggerOnce cascade damping={0.5}>
          {posts.slice(0,4).map((post, i) => (
            <Post {...post} key={i} />
          ))}
        </Fade>
      </CardContainer>
    </Section.Container>
  )
  
}

const Background = () => (
  <>
    <Triangle
      color="muted"
      height={['15vh', '10vh']}
      width={['100vw', '100vw']}
      position="top-left"
    />

    <Triangle
      color="secondary"
      height={['50vh', '40vh']}
      width={['70vw', '40vw']}
      position="bottom-left"
    />

    <Triangle
      color="primary"
      height={['40vh', '15vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
  </>
);

export default Writing
