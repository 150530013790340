import {graphql, useStaticQuery} from 'gatsby'
import {HashNodePost} from '../types'

export type QueryResponse = {
    hnapi: {
        user: {
            publication: {
                posts: {
                    coverImage: string
                    title: string
                    brief: string
                    dateAdded: string
                    slug: string
                }[]
            }
        }
    }
}

interface Response {
    posts: HashNodePost[]
}

export const useHashnodeQuery = (): Response => {
    const data = useStaticQuery<QueryResponse>(graphql`
        query {
            hnapi {
                user(username: "Lloydinator") {
                    publication {
                        posts(page: 0) {
                            coverImage
                            title
                            brief
                            dateAdded
                            slug
                        }
                    }
                }
            }
        }
    `)

    const posts = data.hnapi.user.publication.posts

    return {
        posts
    }
}