import React, { ReactNode } from 'react'
import {format} from 'date-fns'
import { Heading, Text } from 'rebass/styled-components'
import styled from 'styled-components'
import { HashNodePost } from '../types'
import { Card } from 'gatsby-theme-mate/src/components/Card'
import ImageLabel from 'gatsby-theme-mate/src/components/ImageLabel'

export const Post = ({ coverImage, title, brief, dateAdded, slug }: HashNodePost) => {
    const url = "https://blog.lloydmiller.dev/" + slug
    const time = format(new Date(dateAdded), "MMMM dd, yyyy")

    return (
        <PostContainer url={url} title={title}>
            <EllipsisHeading m={3} color="header-text">
                {title}
            </EllipsisHeading>
                {coverImage && 
                    <CoverImage src={coverImage} height="200px" alt={title} />
                }
            <Text m={3} color="smaller-text">
                {brief}
            </Text>
            <ImageLabel bg="primary" color="background" position="bottom-right" round>
                {time}
            </ImageLabel>
        </PostContainer>
    )   
}

type PostContainerProps = {
  url: string;
  title: string;
  children: ReactNode;
};

const PostContainer = ({ url, title, children }: PostContainerProps) => (
  <a
    href={url}
    target="__blank"
    title={title}
    style={{ textDecoration: 'none' }}
  >
    <Card p={0} pb={4}>
      {children}
    </Card>
  </a>
);

const CoverImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const EllipsisHeading = styled(Heading)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
